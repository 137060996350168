import { Injectable } from '@angular/core';
import type { DeviceInfo, DevicePlugin, GetLanguageCodeResult } from '@capacitor/device';
import { LanguageTag } from '@capacitor/device/dist/esm/definitions';
import { defer, map, Observable, switchMap } from 'rxjs';
import { Cacheable } from 'ts-cacheable';

@Injectable({ providedIn: 'root' })
export class IonicDevice {
  private readonly capacitorDevice: Observable<DevicePlugin> = defer(() => import('@capacitor/device')).pipe(map(({ Device }) => Device));

  @Cacheable()
  public getInfo(): Observable<DeviceInfo> {
    return this.capacitorDevice.pipe(
      switchMap(Device => Device.getInfo())
    );
  }

  @Cacheable()
  public getLanguageCode(): Observable<GetLanguageCodeResult> {
    return this.capacitorDevice.pipe(
      switchMap(Device => Device.getLanguageCode())
    );
  }

  @Cacheable()
  public getLanguageTag(): Observable<LanguageTag> {
    return this.capacitorDevice.pipe(
      switchMap(Device => Device.getLanguageTag())
    );
  }
}

export type IIonicDevice = Omit<InstanceType<typeof IonicDevice>, 'capacitorDevice'>;
